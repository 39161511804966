import React, { useContext, useEffect, useState, useCallback } from 'react';
import PaymentDetailModal from './PaymentDetailModal'; // Import the modal component
import { Dropdown, Table, Pagination, Button } from "flowbite-react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faFileExport } from '@fortawesome/free-solid-svg-icons';
import Context from '../../../context/Context';
import "./Revenue.css";
import * as XLSX from 'xlsx';
import { ToastContainer, toast } from "react-toastify"; // Ensure this is imported

// Custom Dropdown Item with our styling
const CustomDropdownItem = ({ onClick, children }) => {
  return (
    <Dropdown.Item
      onClick={onClick}
      className="custom-dropdown-item hover:bg-[#30afbc] hover:text-white transition-colors duration-200">
      {children}
    </Dropdown.Item>
  );
};

const AwsaiappRevenue = () => {
  const { payments, products, clients } = useContext(Context);
  const [filteredPayments, setFilteredPayments] = useState([]);
  const [selectedPayment, setSelectedPayment] = useState({}); // State for selected payment
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;
  const currentPayments = filteredPayments.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);
  const [timeFilter, setTimeFilter] = useState("all"); // "all" or "specific"
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  const [years, setYears] = useState([new Date().getFullYear()]);
  const [paymentGateway, setPaymentGateway] = useState("all"); // "all", "razorpay", or "paypal"
  const [institutionPaymentHistory, setInstitutionPaymentHistory] = useState({});
  const [institutionReccuring, setInstitutionReccuring] = useState();
  const customTheme = {
    pages: {
      base: "xs:mt-0 mt-2 inline-flex items-center -space-x-px",
      showIcon: "inline-flex",
      previous: {
        base: "ml-0 rounded-l-md border border-gray-300 bg-white px-3 py-2 leading-tight text-gray-500 hover:bg-[#30afbc] hover:text-white dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 hover:dark:bg-[#30afbc] hover:dark:text-white",
        icon: "h-5 w-5 text-gray-500 hover:text-white",
      },
      next: {
        base: "rounded-r-md border border-gray-300 bg-white px-3 py-2 leading-tight text-gray-500 hover:bg-[#30afbc] hover:text-white dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 hover:dark:bg-[#30afbc] hover:dark:text-white",
        icon: "h-5 w-5 text-gray-500 hover:text-white",
      },
      selector: {
        base: "w-12 border border-gray-300 bg-white py-2 leading-tight text-gray-500 hover:bg-[#30afbc] hover:text-white dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 hover:dark:bg-[#30afbc] hover:dark:text-white",
        active: "bg-[#30afbc] text-white hover:bg-[#30afbc] hover:text-white",
        disabled: "cursor-not-allowed opacity-50",
      },
    },
  };
  // Collection stats for different time periods
  const [currentMonthStats, setCurrentMonthStats] = useState({
    usd: { totalPayment: 0, transactions: 0 },
    inr: { totalPayment: 0, transactions: 0 },
    total: { totalPayment: 0, transactions: 0 }
  });

  const [lastMonthStats, setLastMonthStats] = useState({
    usd: { totalPayment: 0, transactions: 0 },
    inr: { totalPayment: 0, transactions: 0 },
    total: { totalPayment: 0, transactions: 0 }
  });

  const [currentYearStats, setCurrentYearStats] = useState({
    usd: { totalPayment: 0, transactions: 0 },
    inr: { totalPayment: 0, transactions: 0 },
    total: { totalPayment: 0, transactions: 0 }
  });

  const months = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  // Function to build institution payment history
  const buildInstitutionPaymentHistory = (paymentsList) => {
    const history = {};

    // Sort payments by date (oldest first)
    const sortedPayments = [...paymentsList].sort((a, b) =>
      new Date(a.paymentDate) - new Date(b.paymentDate)
    );

    // Build payment history for each institution
    sortedPayments.forEach(payment => {
      const institution = payment.client || 'unknown';
      if (!history[institution]) {
        history[institution] = [];
      }
      history[institution].push({
        paymentId: payment.paymentId,
        paymentDate: payment.paymentDate
      });
    });

    setInstitutionPaymentHistory(history);
  };

  // Updated function to check if a payment is recurring and get the last payment date
  const getPaymentRecurringInfo = (payment) => {
    const institution = payment.client || 'unknown';
    const paymentDate = new Date(payment.paymentDate);

    // If the institution doesn't have payment history, it's not recurring
    if (!institutionPaymentHistory[institution] || institution === 'unknown')
      return { recurring: false, lastPaymentDate: null };

    // Find the most recent payment that happened before the current one
    const previousPayments = institutionPaymentHistory[institution].filter(prevPayment =>
      prevPayment.paymentId !== payment.paymentId &&
      new Date(prevPayment.paymentDate) < paymentDate
    );

    if (previousPayments.length === 0) return { recurring: false, lastPaymentDate: null };

    // Sort previous payments to get the most recent one
    previousPayments.sort((a, b) => new Date(b.paymentDate) - new Date(a.paymentDate));

    return {
      recurring: true,
      lastPaymentDate: previousPayments[0].paymentDate
    };
  };

  const calculateStats = useCallback((paymentsList) => {

    // Robust timestamp parsing function
    const safeParseTimestamp = (timestamp) => {
      try {
        // Convert to number first
        const ts = Number(timestamp);

        // If timestamp looks like seconds (10 digits), multiply by 1000
        const adjustedTimestamp = ts < 10000000000
          ? ts * 1000
          : ts;

        const date = new Date(adjustedTimestamp);

        // Validate the date
        if (isNaN(date.getTime())) {
          console.error("Invalid timestamp parsing:", {
            original: timestamp,
            parsed: adjustedTimestamp
          });
          return null;
        }

        return date;
      } catch (error) {
        console.error("Error parsing timestamp:", timestamp, error);
        return null;
      }
    };

    // Filter based on payment gateway
    let filteredList = [...paymentsList];
    if (paymentGateway === "razorpay") {
      filteredList = paymentsList.filter(p => p.currency === "INR");
    } else if (paymentGateway === "paypal") {
      filteredList = paymentsList.filter(p => p.currency === "USD");
    }

    // Current month stats
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    const currentMonthPayments = filteredList.filter(payment => {
      const paymentDate = safeParseTimestamp(payment.paymentDate);

      // Validate and check if payment is in current month
      return paymentDate &&
        paymentDate.getMonth() === currentMonth &&
        paymentDate.getFullYear() === currentYear;
    });

    // Last month stats
    let lastMonth = currentMonth - 1;
    let lastMonthYear = currentYear;
    if (lastMonth < 0) {
      lastMonth = 11;
      lastMonthYear--;
    }

    const lastMonthPayments = filteredList.filter(payment => {
      const paymentDate = safeParseTimestamp(payment.paymentDate);

      return paymentDate &&
        paymentDate.getMonth() === lastMonth &&
        paymentDate.getFullYear() === lastMonthYear;
    });

    // Current year stats
    const currentYearPayments = filteredList.filter(payment => {
      const paymentDate = safeParseTimestamp(payment.paymentDate);

      return paymentDate &&
        paymentDate.getFullYear() === currentYear;
    });

    // Detailed stats calculation function
    const calculateDetailedPeriodStats = (periodPayments) => {
      // USD stats
      const usdPayments = periodPayments.filter(p => p.currency === "USD");
      const usdTotal = usdPayments.reduce((sum, p) => sum + (p.amount / 100), 0);

      // INR stats
      const inrPayments = periodPayments.filter(p => p.currency === "INR");
      const inrTotal = inrPayments.reduce((sum, p) => sum + (p.amount / 100), 0);

      // Combined stats
      return {
        usd: {
          totalPayment: usdTotal,
          transactions: usdPayments.length
        },
        inr: {
          totalPayment: inrTotal,
          transactions: inrPayments.length
        },
        total: {
          totalPayment: usdTotal + inrTotal,
          transactions: usdPayments.length + inrPayments.length
        }
      };
    };

    // Calculate stats for each time period
    const currentMonthStats = calculateDetailedPeriodStats(currentMonthPayments);
    const lastMonthStats = calculateDetailedPeriodStats(lastMonthPayments);
    const currentYearStats = calculateDetailedPeriodStats(currentYearPayments);

    // Update state
    setCurrentMonthStats(currentMonthStats);
    setLastMonthStats(lastMonthStats);
    setCurrentYearStats(currentYearStats);

    // Update filtered payments based on current time filter
    let displayPayments = [];
    if (timeFilter === "all") {
      displayPayments = filteredList;
    } else {
      // Filter for specific year and month
      displayPayments = filteredList.filter(payment => {
        const paymentDate = safeParseTimestamp(payment.paymentDate);
        return paymentDate &&
          paymentDate.getFullYear() === Number(selectedYear) &&
          paymentDate.getMonth() === selectedMonth;
      });
    }

    setFilteredPayments(displayPayments);
  }, [paymentGateway, selectedYear, selectedMonth, timeFilter]);

  const updateAvailableYears = useCallback((payments) => {
    const uniqueYears = [...new Set(payments.map((payment) =>
      formatYear(payment.paymentDate)))];
    setYears(uniqueYears);
  },[]);

  useEffect(() => {
    if (payments) {
      buildInstitutionPaymentHistory(payments);
      updateAvailableYears(payments);
      calculateStats(payments);
    }
  }, [payments, selectedYear, selectedMonth, timeFilter, paymentGateway, calculateStats, updateAvailableYears]);


  // Helper function to format currency
  const formatCurrency = (amount, currency) => {
    if (currency === "USD") return `$${amount.toFixed(2)}`;
    if (currency === "INR") return `₹${amount.toFixed(2)}`;
    return amount.toFixed(2);
  };

  // Format date as MM/DD/YYYY
  const formatDate = (timestamp) => {
    const ts = Number(timestamp);

    // Check if the timestamp looks like it needs adjustment
    const adjustedTimestamp = ts < 10000000000
      ? ts * 1000  // Assume it's seconds, convert to milliseconds
      : ts;

    const date = new Date(adjustedTimestamp);

    // Validate the date
    if (isNaN(date.getTime())) {
      console.error("Invalid timestamp:", timestamp);
      return null;
    }

    // Format with leading zeros
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${month}/${day}/${date.getFullYear()}`;
  };

  const formatYear = (timestamp) => {
    const ts = Number(timestamp);

    // Check if the timestamp looks like it needs adjustment
    const adjustedTimestamp = ts < 10000000000
      ? ts * 1000  // Assume it's seconds, convert to milliseconds
      : ts;

    const date = new Date(adjustedTimestamp);

    // Validate the date
    if (isNaN(date.getTime())) {
      console.error("Invalid timestamp:", timestamp);
      return null;
    }
    return `${date.getFullYear()}`;
  };

  // Get current month name
  const currentMonthName = months[new Date().getMonth()];

  // Get last month name
  let lastMonthIndex = new Date().getMonth() - 1;
  if (lastMonthIndex < 0) lastMonthIndex = 11;
  const lastMonthName = months[lastMonthIndex];

  // Get current year
  // const currentYear = new Date().getFullYear();
  const totalPages = Math.ceil(filteredPayments.length / rowsPerPage);

  const nameProduct = (value) => {
    const product = products.find(product => product.productId === value);
    return product ? product.heading : null;
  }

  const institutionType = (Id) => {
    if (!clients || !clients.data) return { Type: null, name: 'N/A', number: 'N/A' };
    const Type = clients.data.find(client => client.institutionid === Id);
    return {
      Type: Type.institutionType,
      name: Type.ownerName || Type.userName || 'N/A',
      number: Type.Query_PhoneNumber || Type.phone || 'N/A',
    };
  }

  // Function to export data to Excel
  const exportToExcel = async () => {
    try {
      // Create array with all payments data
      const exportData = filteredPayments.map(payment => {
        // Calculate renewal date (1 month after payment date)
        const paymentDate = new Date(payment.paymentDate);
        const renewDate = new Date(paymentDate);
        renewDate.setMonth(renewDate.getMonth() + 1);
        // Get recurring info
        const { recurring, lastPaymentDate } = getPaymentRecurringInfo(payment);
        // Determine payment gateway
        const gateway = payment.paymentMode === "offline"
          ? "Offline"
          : payment.currency === "USD"
            ? "PayPal"
            : "Razorpay";
        return {
          "Institution ID": payment.client || 'N/A',
          "Institution Type": institutionType(payment.client).Type || 'N/A',
          "Payer Name": institutionType(payment.client).name || 'N/A',
          "Phone Number": institutionType(payment.client).number || 'N/A',
          "Amount": (payment.amount / 100).toFixed(2),
          "Currency": payment.currency,
          "Product": nameProduct(payment.productId) || 'N/A',
          "Payment Date": formatDate(payment.paymentDate),
          "Renew Date": formatDate(renewDate),
          "Last Payment Date": lastPaymentDate ? formatDate(lastPaymentDate) : 'N/A',
          "Payment Type": recurring ? "Recurring" : "New",
          "Payment Gateway": gateway,
        };
      });

      // Create worksheet and workbook
      const worksheet = XLSX.utils.json_to_sheet(exportData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Payments");

      // Auto-adjust column widths
      const maxWidths = {};

      // Include headers
      const headers = Object.keys(exportData[0]);
      headers.forEach(header => {
        maxWidths[header] = header.length;
      });

      // Find max width for each column
      exportData.forEach(row => {
        Object.keys(row).forEach(key => {
          const cellValue = String(row[key]);
          maxWidths[key] = Math.max(maxWidths[key] || 0, cellValue.length);
        });
      });

      // Set column widths
      worksheet['!cols'] = Object.keys(maxWidths).map(key => ({ wch: maxWidths[key] + 2 }));

      // Generate filename
      const timeString = timeFilter === "all"
        ? "All-Time"
        : `${months[selectedMonth]}-${selectedYear}`;
      const gatewayString = paymentGateway === "all" ? "All-Gateways" : paymentGateway;
      const suggestedFileName = `Revenue-Report-${timeString}-${gatewayString}.xlsx`;

      // Generate Excel file as blob (do this before the if/else)
      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      const excelBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      // Check if File System Access API is available
      if ('showSaveFilePicker' in window) {
        // Use the File System Access API
        const fileHandle = await window.showSaveFilePicker({
          suggestedName: suggestedFileName,
          types: [{
            description: 'Excel Files',
            accept: {
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
            },
          }],
        });

        // Create a writable stream
        const writableStream = await fileHandle.createWritable();

        // Write the blob to the file
        await writableStream.write(excelBlob);

        // Close the stream to save the file
        await writableStream.close();

        toast.success("The Revenue sheet is saved successfully");
      } else {
        // Fall back to traditional download method
        const url = URL.createObjectURL(excelBlob);
        const link = document.createElement('a');
        link.href = url;
        link.download = suggestedFileName;
        document.body.appendChild(link);
        link.click();

        setTimeout(() => {
          document.body.removeChild(link);
          URL.revokeObjectURL(url);
        }, 100);

        toast.success("The Revenue sheet is downloaded successfully");
        toast.info("To choose save location, adjust your browser's download settings");
      }

    } catch (error) {
      console.error("Export failed:", error);
      if (error.name === 'AbortError') {
        toast.info("Export was canceled.");
      } else {
        toast.error("Failed to export. Please try again.");
      }
    }
  };

  return (
    <div className="min-h-screen wSet [@media(max-width:1400px)]:w-[80%] [@media(max-width:670px)]:w-[70%] [@media(max-width:1400px)]:ml-[11rem] [@media(max-width:900px)]:ml-[5rem] [@media(max-width:900px)]:mb-[5rem]">
      <ToastContainer />
      {!isModalOpen ? (
        <div className="mdValue md:ml-64 md:mr-[8rem] pt-4 pb-20 md:pb-4 px-4 [@media(max-width:800px)]:ml-[10rem] [@media(max-width:670px)]:mr-[0] ">
          <div className="max-w-7xl mx-auto ">
            {/* Collection Panels */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
              {/* March Collection */}
              <div className="bg-white rounded-lg shadow p-4">
                <div className="flex items-center gap-2 mb-4">
                  <span className="bg-gray-200 p-2 rounded">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-600" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M4 4a2 2 0 00-2 2v8a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2H4zm2 3a1 1 0 011-1h6a1 1 0 110 2H7a1 1 0 01-1-1zm1 3a1 1 0 100 2h6a1 1 0 100-2H7z" clipRule="evenodd" />
                    </svg>
                  </span>
                  <h3 className="text-lg font-medium">{currentMonthName} Collection</h3>
                </div>
                <div className="flex flex-col mb-2">
                  <div className="bg-gray-50 p-2 rounded mb-2">
                    <div className="flex justify-between">
                      <p className="text-sm text-gray-500">USD:</p>
                      <p className="text-md font-bold text-blue-600">${currentMonthStats.usd.totalPayment}</p>
                    </div>
                    <div className="flex justify-between">
                      <p className="text-sm text-gray-500">INR:</p>
                      <p className="text-md font-bold text-blue-600">₹{currentMonthStats.inr.totalPayment}</p>
                    </div>
                  </div>
                  <div className="bg-gray-50 p-2 rounded">
                    <div className="flex justify-around">
                      <div className="text-center">
                        <p className="text-sm font-medium text-blue-600">{currentMonthStats.usd.transactions}</p>
                        <p className="text-xs text-gray-500">USD Trans</p>
                      </div>
                      <div className="text-center">
                        <p className="text-sm font-medium text-blue-600">{currentMonthStats.inr.transactions}</p>
                        <p className="text-xs text-gray-500">INR Trans</p>
                      </div>
                      <div className="text-center">
                        <p className="text-sm font-medium text-blue-600">{currentMonthStats.total.transactions}</p>
                        <p className="text-xs text-gray-500">Total</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* February Collection */}
              <div className="bg-white rounded-lg shadow p-4">
                <div className="flex items-center gap-2 mb-4">
                  <span className="bg-gray-200 p-2 rounded">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-600" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M4 4a2 2 0 00-2 2v8a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2H4zm2 3a1 1 0 011-1h6a1 1 0 110 2H7a1 1 0 01-1-1zm1 3a1 1 0 100 2h6a1 1 0 100-2H7z" clipRule="evenodd" />
                    </svg>
                  </span>
                  <h3 className="text-lg font-medium">{lastMonthName} Collection</h3>
                </div>
                <div className="flex flex-col mb-2">
                  <div className="bg-gray-50 p-2 rounded mb-2">
                    <div className="flex justify-between">
                      <p className="text-sm text-gray-500">USD:</p>
                      <p className="text-md font-bold text-blue-600">${lastMonthStats.usd.totalPayment.toFixed(2)}</p>
                    </div>
                    <div className="flex justify-between">
                      <p className="text-sm text-gray-500">INR:</p>
                      <p className="text-md font-bold text-blue-600">₹{lastMonthStats.inr.totalPayment.toFixed(2)}</p>
                    </div>
                  </div>
                  <div className="bg-gray-50 p-2 rounded">
                    <div className="flex justify-around">
                      <div className="text-center">
                        <p className="text-sm font-medium text-blue-600">{lastMonthStats.usd.transactions}</p>
                        <p className="text-xs text-gray-500">USD Trans</p>
                      </div>
                      <div className="text-center">
                        <p className="text-sm font-medium text-blue-600">{lastMonthStats.inr.transactions}</p>
                        <p className="text-xs text-gray-500">INR Trans</p>
                      </div>
                      <div className="text-center">
                        <p className="text-sm font-medium text-blue-600">{lastMonthStats.total.transactions}</p>
                        <p className="text-xs text-gray-500">Total</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* This Year Collection */}
              <div className="bg-white rounded-lg shadow p-4">
                <div className="flex items-center gap-2 mb-4">
                  <span className="bg-gray-200 p-2 rounded">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-600" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M4 4a2 2 0 00-2 2v8a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2H4zm2 3a1 1 0 011-1h6a1 1 0 110 2H7a1 1 0 01-1-1zm1 3a1 1 0 100 2h6a1 1 0 100-2H7z" clipRule="evenodd" />
                    </svg>
                  </span>
                  <h3 className="text-lg font-medium">This Year Collection</h3>
                </div>
                <div className="flex flex-col mb-2">
                  <div className="bg-gray-50 p-2 rounded mb-2">
                    <div className="flex justify-between">
                      <p className="text-sm text-gray-500">USD:</p>
                      <p className="text-md font-bold text-blue-600">${currentYearStats.usd.totalPayment.toFixed(2)}</p>
                    </div>
                    <div className="flex justify-between">
                      <p className="text-sm text-gray-500">INR:</p>
                      <p className="text-md font-bold text-blue-600">₹{currentYearStats.inr.totalPayment.toFixed(2)}</p>
                    </div>
                  </div>
                  <div className="bg-gray-50 p-2 rounded">
                    <div className="flex justify-around">
                      <div className="text-center">
                        <p className="text-sm font-medium text-blue-600">{currentYearStats.usd.transactions}</p>
                        <p className="text-xs text-gray-500">USD Trans</p>
                      </div>
                      <div className="text-center">
                        <p className="text-sm font-medium text-blue-600">{currentYearStats.inr.transactions}</p>
                        <p className="text-xs text-gray-500">INR Trans</p>
                      </div>
                      <div className="text-center">
                        <p className="text-sm font-medium text-blue-600">{currentYearStats.total.transactions}</p>
                        <p className="text-xs text-gray-500">Total</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Filter Section */}
            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-3 mb-4">
              <div className="flex flex-wrap gap-2">
                <Dropdown
                  label={timeFilter === "all" ? "All time" : `${selectedYear}`}
                  style={{ backgroundColor: "#30afbc", color: "white" }}
                >
                  <CustomDropdownItem onClick={() => setTimeFilter("all")}>
                    All time
                  </CustomDropdownItem>
                  <Dropdown.Divider />
                  {years.map(year => (
                    <CustomDropdownItem key={year} onClick={() => {
                      setSelectedYear(year);
                      setCurrentPage(1);
                      setTimeFilter("specific");
                    }}>
                      {year}
                    </CustomDropdownItem>
                  ))}
                </Dropdown>

                {timeFilter === "specific" && (
                  <Dropdown
                    label={months[selectedMonth]}
                    style={{ backgroundColor: "#30afbc", color: "white" }}
                  >
                    {months.map((month, index) => (
                      <CustomDropdownItem key={month} onClick={() => setSelectedMonth(index)}>
                        {month}
                      </CustomDropdownItem>
                    ))}
                  </Dropdown>
                )}
              </div>

              <div className="flex flex-wrap gap-2">
                <Dropdown
                  label={paymentGateway === "all" ? "All Gateways" : paymentGateway === "razorpay" ? "Razorpay" : "PayPal"}
                  style={{ backgroundColor: "#30afbc", color: "white" }}
                >
                  <CustomDropdownItem onClick={() => setPaymentGateway("all")}>
                    All Gateways
                  </CustomDropdownItem>
                  <CustomDropdownItem onClick={() => {
                    setPaymentGateway("razorpay");
                    setCurrentPage(1);
                  }}>
                    Razorpay (INR)
                  </CustomDropdownItem>
                  <CustomDropdownItem onClick={() => {
                    setPaymentGateway("paypal");
                    setCurrentPage(1);
                  }}>
                    PayPal (USD)
                  </CustomDropdownItem>
                </Dropdown>

                <Button
                  onClick={exportToExcel}
                  disabled={filteredPayments.length === 0}
                  style={{ backgroundColor: "#30afbc", color: "white" }}
                  className="flex items-center gap-2"
                >
                  <FontAwesomeIcon icon={faFileExport} />
                  <span>Export CSV</span>
                </Button>
              </div>
            </div>

            {/* Table Section */}
            <div className="bg-white rounded-lg shadow overflow-hidden">
              <div className="overflow-x-auto">
                <Table hoverable>
                  <Table.Head>
                    <Table.HeadCell className="px-4 py-3 text-xs font-medium text-gray-500 uppercase">Institution</Table.HeadCell>
                    <Table.HeadCell className="px-4 py-3 text-xs font-medium text-gray-500 uppercase">Payer</Table.HeadCell>
                    <Table.HeadCell className="px-4 py-3 text-xs font-medium text-gray-500 uppercase">Phone Number</Table.HeadCell>
                    <Table.HeadCell className="px-4 py-3 text-xs font-medium text-gray-500 uppercase">Amount</Table.HeadCell>
                    <Table.HeadCell className="px-4 py-3 text-xs font-medium text-gray-500 uppercase">Product</Table.HeadCell>
                    <Table.HeadCell className="px-4 py-3 text-xs font-medium text-gray-500 uppercase">Payment Date</Table.HeadCell>
                    <Table.HeadCell className="px-4 py-3 text-xs font-medium text-gray-500 uppercase">Payment Type</Table.HeadCell>
                    <Table.HeadCell className="px-4 py-3 text-xs font-medium text-gray-500 uppercase">Payment Gateway</Table.HeadCell>
                    <Table.HeadCell className="px-4 py-3 text-xs font-medium text-gray-500 uppercase">More</Table.HeadCell>
                  </Table.Head>
                  <Table.Body className="divide-y">
                    {currentPayments.length === 0 ? (
                      <Table.Row>
                        <Table.Cell colSpan={9} className="px-4 py-8 text-center text-gray-500">
                          No Data Found
                        </Table.Cell>
                      </Table.Row>
                    ) : (
                      currentPayments.map((payment, index) => {
                        // Calculate renewal date
                        // const paymentDate = new Date(payment.paymentDate);
                        // const renewDate = new Date(paymentDate);
                        // renewDate.setMonth(renewDate.getMonth() + 1);

                        // Determine payment gateway
                        const gateway = payment.currency === "USD"
                          ? "PayPal"
                          : "Razorpay";

                        // Get recurring info
                        const { recurring, lastPaymentDate } = getPaymentRecurringInfo(payment);

                        return (
                          <Table.Row key={payment.paymentId || index}>
                            <Table.Cell className="px-4 py-3 whitespace-nowrap text-sm text-gray-500">
                              {payment.client || 'N/A'}
                            </Table.Cell>
                            <Table.Cell className="px-4 py-3 whitespace-nowrap text-sm text-gray-500">
                              {institutionType(payment.client).name}
                            </Table.Cell>

                            <Table.Cell className="px-4 py-3 whitespace-nowrap text-sm text-gray-500">
                              {institutionType(payment.client).number}
                            </Table.Cell>

                            <Table.Cell className="px-4 py-3 whitespace-nowrap text-sm text-gray-500">
                              {formatCurrency(payment.amount / 100, payment.currency)}
                            </Table.Cell>
                            <Table.Cell className="px-4 py-3 whitespace-nowrap text-sm text-gray-500">
                              {nameProduct(payment.productId)}
                            </Table.Cell>
                            <Table.Cell className="px-4 py-3 whitespace-nowrap text-sm text-gray-500">
                              {formatDate(payment.paymentDate)}
                            </Table.Cell>
                            <Table.Cell className="px-2 py-3 whitespace-nowrap text-sm">
                              <span className={`px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full 
                                ${recurring ? "bg-yellow-100 text-yellow-800" : "bg-green-100 text-green-800"}`}>
                                {recurring ? "Recurring" : "New"}
                              </span>
                            </Table.Cell>
                            <Table.Cell className="px-1 py-3 whitespace-nowrap text-sm">
                              <span className={`px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full ${gateway === "PayPal"
                                ? "bg-blue-100 text-blue-800"
                                : gateway === "Razorpay"
                                  ? "bg-purple-100 text-purple-800"
                                  : "bg-gray-100 text-gray-800"
                                }`}>
                                {gateway}
                              </span>
                            </Table.Cell>
                            <Table.Cell className="px-4 py-3 whitespace-nowrap text-sm text-center text-gray-500">
                              <button aria-label="View" onClick={() => {
                                // Create a copy of the payment with the lastPaymentDate and renewDate added
                                const paymentWithDates = {
                                  lastPaymentDate: lastPaymentDate,
                                  ...payment,
                                  renewDate: formatDate(payment.renewDate),
                                };
                                setSelectedPayment(paymentWithDates);
                                setIsModalOpen((prev) => !prev);
                                setInstitutionReccuring(recurring);
                              }}>
                                <FontAwesomeIcon icon={faEye} className="text-[#30afbc]" />
                              </button>
                            </Table.Cell>
                          </Table.Row>
                        );
                      }))}
                  </Table.Body>
                </Table>
              </div>
              {/* Pagination */}
              <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6 flex-col sm:flex-row space-y-2 sm:space-y-0 pagination-container">
                <div className="text-sm text-gray-700">
                  Showing {(currentPage - 1) * rowsPerPage + 1}-{Math.min(currentPage * rowsPerPage, filteredPayments.length)} of {filteredPayments.length}
                </div>
                <Pagination
                  currentPage={filteredPayments.length > 0 ? currentPage : 1}
                  totalPages={filteredPayments.length > 0 ? totalPages : 0}
                  onPageChange={setCurrentPage}
                  className="flex justify-end"
                  showIcons
                  theme={customTheme}
                  disabled={filteredPayments.length === 0}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <PaymentDetailModal payment={selectedPayment} onClose={() => setIsModalOpen(false)} isOpen={true} recurringValue={institutionReccuring} />
      )}
    </div>
  );
};

export default AwsaiappRevenue;
